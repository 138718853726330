import ChallengeDetail from 'components/ChallengeDetail'
import { Button, Drawer, Modal } from 'rsuite'
import history from 'App/History'
import ChallengeResultTable from 'components/ChallengeResultTable'
import { useParams } from 'react-router-dom'
import { useTypedDispatch, useTypedSelector } from 'App/Store'
import { useEffect, useState } from 'react'
import { groupDetails as getGroupDetails } from "redux/action/groupDetailsAction";
import { DEFAULT_API_ERROR } from 'core/constants/Defaults'
import { hideLoader, showLoader } from 'redux/action/loaderAction'
import { addRewards, getRewards } from 'core/services/Groups'
import { playoffRoundsList } from 'redux/action/playoffRoundsAction'
import { useNotification } from 'shared/hooks/useNotification'
import PrizeDistribution from 'components/PrizeDistribution'
import ViewRewards from 'components/ViewRewards'
import { Reward } from 'core/models/Reward'

interface UrlParams {
  challengeId: string;
}
const BLANK_REWARD: Reward = {
  amount: 0,
  id: 0,
  currency: '',
  inGameName: '',
  rewardedBy: 0,
  teamName: '',
  tournamentId: '',
  userId: 0,
  userName: '',
  name: ''
}


export default function ChallengeDetailPage() {

  const urlParams = useParams<UrlParams>();
  const dispatch = useTypedDispatch();
  const challengeDetail = useTypedSelector((state) => state.groupDetails);
  const { groupDetails } = challengeDetail;
  const [screenWidth, setScreenWidth] = useState(0)
  const [isMobile, setMobile] = useState(true)
  const showNotification = useNotification()
  const playoffRoundDetails = useTypedSelector(state => state.playoffRoundDetails)
  const { prizeConfig, winnerTeams, rewarded, tournamentCompleted } = playoffRoundDetails
  const [showPrize, setShowPrize] = useState(false)
  const [showReward, setShowReward] = useState(false)
  const [rewards, setRewards] = useState<Reward[]>([])

  useEffect(() => {
    dispatch(getGroupDetails({ id: urlParams.challengeId }));
  }, [dispatch, urlParams.challengeId]);

  useEffect(() => {
    dispatch(playoffRoundsList({ id: groupDetails[0]?.tournamentId }))

  }, [dispatch, groupDetails])

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  function handleResize() {
    setScreenWidth(
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    )
  }

  useEffect(() => {
    if (screenWidth > 540) {
      setMobile(false)
    }
    else setMobile(true)
  }, [screenWidth])

  function openDetails() {
    history.push(`/challenges/${groupDetails[0].groupId}/result`)
  }

  async function handleViewReward() {
    const rewardRes = await getRewards(groupDetails[0].tournamentId)
    if (rewardRes.status && rewardRes.data) {
      const rewardsData = rewardRes.data.rewards.map(reward => {
        if (reward.currency === 'XOCOINS') {
          reward.currency = 'XO Coins'
        } else {
          reward.currency = 'XO Points'
        }
        return reward
      })
      if (rewardsData.length > 4) {
        rewardsData.push(BLANK_REWARD)
      }
      setRewards(rewardsData)
      setShowReward(true)
    } else {
      showNotification({
        message: rewardRes.message || DEFAULT_API_ERROR,
        title: 'Error',
        type: 'error'
      })
    }
  }

  function handlePrizeDistribution(prizeData: any) {
    dispatch(showLoader())
    addRewards({ ...prizeData, tournId: groupDetails[0].tournamentId })
      .then(resp => {
        if (resp.status && resp.data) {
          dispatch(playoffRoundsList({ id: groupDetails[0].tournamentId }))
          showNotification({
            message: 'Rewards Added Successfully',
            title: 'Success',
            type: 'success'
          })
        } else {
          showNotification({
            message: resp.message || DEFAULT_API_ERROR,
            title: 'Error',
            type: 'error'
          })
        }
        handleClose()
        dispatch(hideLoader())
      })
  }
  function handleClose() {
    setShowPrize(false);
  }

  function handlePrizeDistributionOpen() {
    setShowPrize(true);
  }

  function closeViewReward() {
    setTimeout(() => { setRewards([]) }, 300)
    setShowReward(false)
  }

  return (
    <>
      <div className='challenge-detail-container-page'>
        {
          !groupDetails[0]?.isCompleted
          &&
          <div className='result-button-container'>
            <Button appearance="primary" className='btn' size={isMobile ? 'xs' : 'sm'} onClick={openDetails}>Add Results</Button>
          </div>
        }
        {
          Boolean(prizeConfig?.length && tournamentCompleted && !rewarded) &&
          <div className='result-button-container'>
            <Button id='add-result-btn' appearance="primary" className='btn' onClick={handlePrizeDistributionOpen} size={isMobile ? 'xs' : 'sm'}>Add Reward</Button>
          </div>
        }
        {
          Boolean(prizeConfig?.length && tournamentCompleted && rewarded) &&
          <div className='result-button-container'>
            <Button id='view-reward-btn' className='btn' appearance="primary" onClick={handleViewReward} size={isMobile ? 'xs' : 'sm'}>View Reward</Button>
          </div>
        }
        <ChallengeDetail challengeDetail={groupDetails[0]} />
        {
          !!groupDetails[0]?.roundResults?.length &&
          <>
            <div className='result-container'>
              <div className='sub-heading'>Results</div>
            </div>
            <ChallengeResultTable isMobile={isMobile} challengeDetail={groupDetails[0]} />
          </>
        }
      </div>
      {
        isMobile ?
          <Drawer placement="bottom" onHide={handleClose} show={showPrize} style={{ height: 'var(--app-height)' }}>
            <Drawer.Header>
              <Drawer.Title>Prize Distribution</Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
              {
                showPrize &&
                <PrizeDistribution
                  onClose={handleClose}
                  teams={winnerTeams}
                  prizeConfig={prizeConfig}
                  onAddRewards={handlePrizeDistribution}
                />
              }
            </Drawer.Body>
          </Drawer>
          :
          <Modal onHide={handleClose} show={showPrize} size='md'>
            <Modal.Header>
              <Modal.Title>Prize Distribution</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {
                showPrize &&
                <PrizeDistribution
                  onClose={handleClose}
                  teams={winnerTeams}
                  prizeConfig={prizeConfig}
                  onAddRewards={handlePrizeDistribution}
                />
              }
            </Modal.Body>
          </Modal>
      }
      {
        isMobile ?
          <Drawer placement="bottom" onHide={closeViewReward} show={showReward}>
            <Drawer.Header>
              <Drawer.Title>Rewards</Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
              <ViewRewards
                rewards={rewards}
              />
            </Drawer.Body>
          </Drawer>
          :
          <Modal onHide={closeViewReward} show={showReward} size="lg" >
            <Modal.Header>
              <Modal.Title>Rewards</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ViewRewards
                rewards={rewards}
              />
            </Modal.Body>
          </Modal>
      }
    </>
  )
}
